// import React from "react";

// function sidebar(props){

//     const noteElements = props.notes.map ((note, index) =>(
//         <div key={note.id}>
//             <div className={`title ${
//                 note.id === props.currentNote.id ? "selected-note" : ""
//             }`}
//             onClick={() => props.setCurrentNoteId(note.id)}>
//                 <h4 className="text-snippet"> Note {index + 1}</h4>
//             </div>
//         </div>
//     ))

//     return (
//       <section className="pane-sidebar">
//           <div className="sidebar--header">
//               <h3> Notes </h3>
//               <button className="new-note" onClick={props.newNote}>+</button>
//           </div>
//           {noteElements}
//       </section>
//     )
// }

// export default sidebar;


/* Notes App - Add note summary titles */

import React from "react";

function sidebar(props){

    const noteElements = props.notes.map ((note, index) =>(
        <div key={note.id}>
            <div className={`title ${
                note.id === props.currentNote.id ? "selected-note" : ""
            }`}
            onClick={() => props.setCurrentNoteId(note.id)}>
                <h4 className="text-snippet"> {note.body.split("\n")[0]}</h4>
                <button className="delete-btn" onClick={(event) => props.deleteNote(event,note.id)}
                >
                <i className="gg-trash trash-icon"></i>
                </button>
            </div>
        </div>
    ))

    return (
      <section className="pane-sidebar">
          <div className="sidebar--header">
              <h3 className="note"> Notes </h3>
              <button className="new-note" onClick={props.newNote}>+</button>
          </div>
          {noteElements}
      </section>
    )
}

export default sidebar;

/* Move modified notes to top of list -- refer App.js*/

/* When a user edits a note , recent edits will be in the top of list */ 